<template>

    <q-layout view="hHh lpr fFf" id="piattaforma-rami">

        <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="sta_caricando"
            style="z-index: 1000;">
            <q-spinner-hourglass
                size="4em"
            />
        </div>

        <q-page-container style="padding-left: 16px; padding-right: 16px;">
            <br>
            <div class="row justify-center">

                <div class="col-12 col-md-12" align="center" v-if="isAby">
                    <q-img
                        style="width: 300px;"
                        :src="path_logo" />
                </div>

                <div class="col-12 col-md-12" align="center" v-if="!isAby">
                    <h3>{{ getRagioneSocialeUfficio }}</h3>
                </div>

                <div class="col-12 col-md-8">

                    <br>
                    <q-toolbar class="bg-primary text-white shadow-2">
                        <q-toolbar-title><div align="center">BOX INFORMATIVO</div></q-toolbar-title>
                    </q-toolbar>
                    <q-list bordered>
                        <q-item>
                            <q-item-label class="text-h5">{{ getNominativoCliente }}</q-item-label>
                        </q-item>
                        <q-item>
                            <q-item-label><strong>Preventivo N.</strong> {{ getNumeroPreventivo() }}</q-item-label>
                        </q-item>
                        <q-item>
                            <q-item-label><strong>Valido fino al </strong> {{ getDataScadenzaPreventivo }}</q-item-label>
                        </q-item>
                    </q-list>

                    <q-expansion-item
                        expand-separator
                        label="IL MIO ASSICURATORE"
                        v-model="mio_assicuratore"
                        class="bg-primary text-white text-h6 q-mb-lg"
                    >
                        <div style="background-color: #fff; color: #000;" class="text-left">
                            <q-list bordered>
                                <q-item>
                                    <q-item-section>
                                        <q-item-label caption lines="1">IL MIO CONSULENTE PERSONALE</q-item-label>
                                        <q-item-label>{{ getIntermediario }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item>
                                    <q-item-section>
                                        <q-item-label caption lines="1">LA SUA SEZIONE RUI</q-item-label>
                                        <q-item-label>{{ getRUIIntermediario.numero_rui }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item>
                                    <q-item-section>
                                        <q-item-label caption lines="1">TELEFONO</q-item-label>
                                        <q-item-label>{{ getRecapitiIntermediario.telefono }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item>
                                    <q-item-section>
                                        <q-item-label caption lines="1">EMAIL</q-item-label>
                                        <q-item-label>{{ getRecapitiIntermediario.email }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </div>
                    </q-expansion-item>

                    <q-expansion-item
                        expand-separator
                        label="LA PIATTAFORMA ABYWAY"
                        class="bg-primary text-white text-h6 q-mb-lg"
                    >
                        <div style="background-color: #fff; color: #000; font-size: 16px; padding: 8px; color: #808080;" class="text-left">

                            La piattaforma ABYWAY opera da oltre 10 anni. Rappresenta un nuovo modo di lavorare in ambito assicurativo ed è divenuta un vero punto di
                            riferimento per centinaia di intermediari di tutta Italia. Attualmente la piattaforma gestisce più di 70.000 clienti

                        </div>
                    </q-expansion-item>

                    <q-expansion-item
                        expand-separator
                        label="IL SISTEMA ABYWAY"
                        class="bg-primary text-white text-h6 q-mb-lg"
                    >
                        <div style="background-color: #fff; color: #000; font-size: 16px; padding: 8px; color: #808080;" class="text-left">
                            <strong>La rivoluzione degli ultimi 20 anni</strong>
                            <br>

                            AbyWay è un sistema operativo evoluto, messo a punto con anni di esperienza. Prevede una gestione molto avanzata del Cliente,
                            attraverso l’elaborazione di questi punti.
                            <ul>
                                <li>Profilazione del Cliente attraverso la verifica della sua situazione assicurativa. </li>
                                <li>Comparazione fra un centinaio di tariffe disponibili per la ricerca della migliore soluzione.</li>
                                <li>Scelta della tariffa, adattando l’offerta alle specifiche esigenze del Cliente.</li>
                                <li>In caso di acquisto della polizza il compenso dell’intera struttura è ricavato, in modo del tutto trasparente, dal costo
                                    della consulenza assicurativa che viene fattura a parte rispetto al costo della polizza e degli eventuali servizi. </li>
                                <li>Il risparmio medio per un nuovo cliente è di oltre il 20%!</li>
                            </ul>

                        </div>
                    </q-expansion-item>

                    <q-expansion-item
                        expand-separator
                        v-model="dettagli_proposte"
                        label="DETTAGLIO PROPOSTE"
                        class="bg-primary text-white text-h6 q-mb-lg"
                    >
                        <div style="background-color: #fff; color: #000;" class="text-left text-h7">
                            <br>
                            <div v-for="(prodotto,index) in getProdottiSelezionati" v-bind:key="index">
                                <fieldset>
                                    <legend>{{ prodotto.label }}</legend>
                                    <br>

                                    <q-list bordered style="font-size: 16px;">
                                        <q-item>
                                            <q-item-section>
                                                <q-item-label caption lines="1">FRAZIONAMENTO</q-item-label>
                                                <q-item-label>{{ getFrazionamento(prodotto) }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-item>
                                            <q-item-section>
                                                <q-item-label caption lines="1">COMPAGNIA</q-item-label>
                                                <q-item-label>{{ getPropostaSelezionata(prodotto).compagnia.replaceAll("_"," ") }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                    </q-list>
                                    <br>
                                    <q-list bordered style="font-size: 16px;" v-if="documenti_catalogo.length > 0">
                                        <div v-for="(documento_catalogo,indexCatalogo) in documenti_catalogo[index].documenti"
                                                    v-bind:key="indexCatalogo">
                                            <q-item clickable v-ripple style="background-color: #ffffc0;" class="q-mb-sm">
                                                <q-item-section
                                                    @click="ScaricaDocumento(documento_catalogo.path,documenti_contratti[index].filename)">
                                                    <q-item-label><strong>{{ documento_catalogo.descrizione }}</strong></q-item-label>
                                                </q-item-section>
                                                <q-item-section avatar>
                                                <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                            </q-item-section>
                                            </q-item>
                                        </div>
                                    </q-list>
                                </fieldset>
                            </div>
                        </div>
                    </q-expansion-item>

                    <q-expansion-item
                        expand-separator
                        v-model="offerta_economica"
                        label="OFFERTA ECONOMICA"
                        class="bg-primary text-white text-h6 q-mb-lg"
                    >
                        <div style="background-color: #fff; color: #000; font-size: 18px;" class="text-left">
                            <br >
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Settore di polizza</th>
                                    <th>Compagnia</th>
                                    <th>Premio</th>
                                </tr>
                            </thead>
                            <tbody v-for="(prodotto,index) in getProdottiSelezionati"
                                v-bind:key="index">
                                <tr>
                                    <td>{{ prodotto.label }}</td>
                                    <td>{{ getPropostaSelezionata(prodotto).compagnia.replaceAll("_"," ") }}</td>
                                    <td align="right">{{ getPremioProposta(prodotto) }} &euro;</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="2" align="right">CONSULENZA TOTALE:</td>
                                    <td align="right"><strong style="color: #800000;">{{ getConsulenzaTotale() }} &euro;</strong></td>

                                </tr>
                                <tr>
                                    <td colspan="2" align="right"><strong>TOTALE:</strong></td>
                                    <td align="right"><strong style="color: #800000;">{{ getPremioTotale() }} &euro;</strong></td>
                                </tr>
                            </tfoot>
                        </table>
                        <br>
                        </div>
                    </q-expansion-item>

                    <q-expansion-item
                        expand-separator
                        v-model="documenti_leggere"
                        label="DOCUMENTI DA LEGGERE"
                        class="bg-primary text-white text-h6 q-mb-lg">
                        <div style="background-color: #fff; color: #000;" class="text-left text-h7">
                            <br>
                            <q-list bordered>
                                <div v-for="(altro,index) in documenti_allegati.documenti"
                                    v-bind:key="index">

                                    <q-item clickable v-ripple v-if="altro.descrizione !== 'dichiarazioniCliente.pdf'"
                                        style="background-color: #ffffc0; font-size: 18px;" class="q-mb-sm"
                                    >

                                        <q-item-section
                                            @click="ScaricaDocumento(altro.path,altro.id)">
                                            <strong>{{ altro.descrizione }}</strong>
                                        </q-item-section>

                                        <q-item-section avatar
                                            @click="ScaricaDocumento(altro.path,altro.id)">
                                            <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                        </q-item-section>

                                    </q-item>
                                </div>

                            </q-list>
                        </div>
                    </q-expansion-item>

                    <q-expansion-item
                        expand-separator
                        v-model="documenti_sottoscrivere"
                        label="DOCUMENTI DA SOTTOSCRIVERE"
                        class="bg-primary text-white text-h6 q-mb-lg">
                        <div style="background-color: #fff; color: #000;" class="text-left text-h7">
                            <br>
                            <q-list bordered>
                                <div v-for="(altro,index) in documenti_allegati.documenti"
                                    v-bind:key="index">

                                    <q-item clickable v-ripple v-if="altro.descrizione === 'dichiarazioniCliente.pdf'"
                                        style="background-color: #ffffc0; font-size: 18px;" class="q-mb-sm"
                                    >

                                        <q-item-section
                                            @click="ScaricaDocumento(altro.path,altro.id)">
                                            <strong>{{ altro.descrizione }}</strong>
                                        </q-item-section>

                                        <q-item-section avatar
                                            @click="ScaricaDocumento(altro.path,altro.id)">
                                            <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                        </q-item-section>


                                    </q-item>
                                </div>

                            </q-list>
                        </div>
                    </q-expansion-item>

                </div>

            </div>
        </q-page-container>

    </q-layout>

<!--    <q-layout view="hHh lpr fFf" id="piattaforma-rami">

        <Header />

        <q-page-container style="padding-left: 16px; padding-right: 16px;">
            <br>
            <div class="row justify-center">
                <div class="col-12 col-md-10">
                     <div class="text-h4">Ciao {{preventivo.nominativo}}</div>
                    <br>
                    qui troverai tutte le informazioni relative al preventivo numero <strong style="color: #800000;">{{ getNumeroPreventivo() }}</strong>
                    da te richiesto.
                    <br><br><br>
                </div>

                <div class="col-12 col-md-5">
                    <fieldset style="min-height: 200px;">
                        <legend><strong>Informazioni generali del preventivo</strong></legend>
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td class="domanda">Intermediario: </td>
                                    <td class="risposta">{{ getIntermediario }}</td>
                                </tr>
                                <tr>
                                    <td class="domanda">Data inserimento: </td>
                                    <td class="risposta">{{ getDataInserimento }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <br>
                    <fieldset>
                        <legend><strong>Lista delle proposte selezionate</strong></legend>
                        <br >
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Settore di polizza</th>
                                    <th>Compagnia</th>
                                    <th>Premio</th>
                                </tr>
                            </thead>
                            <tbody v-for="(prodotto,index) in getProdottiSelezionati"
                                v-bind:key="index">
                                <tr>
                                    <td>{{ prodotto.label }}</td>
                                    <td>{{ getPropostaSelezionata(prodotto).compagnia }}</td>
                                    <td align="right">{{ getPremioProposta(prodotto) }} &euro;</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colspan="2" align="right">CONSULENZA TOTALE:</td>
                                    <td align="right"><strong style="color: #800000;">{{ getConsulenzaTotale() }} &euro;</strong></td>

                                </tr>
                                <tr>
                                    <td colspan="2" align="right"><strong>TOTALE:</strong></td>
                                    <td align="right"><strong style="color: #800000;">{{ getPremioTotale() }} &euro;</strong></td>
                                </tr>
                            </tfoot>
                        </table>
                        <br>
                    </fieldset>
                    <br><br><br><br>
                </div>
                <div class="col-12 col-md-5">
                    <fieldset style="min-height: 200px;">
                        <legend><strong>Documenti associati al preventivo</strong></legend>
                        <br>
                        <q-list bordered separator>

                            <q-item-label header>Altri documenti</q-item-label>

                            <q-item clickable v-ripple
                                v-for="(altro,index) in documenti_allegati.documenti"
                                v-bind:key="index">

                                <q-item-section avatar>
                                    <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                </q-item-section>

                                <q-item-section
                                    @click="ScaricaDocumento(altro.path,altro.id)">
                                    <strong>{{ altro.descrizione }}</strong>
                                </q-item-section>
                                <q-item-section side>
                                <q-item-label caption>{{ trascodificaAzione(altro.azione) }}</q-item-label>
                                </q-item-section>

                            </q-item>

                            <div v-for="(documento_prodotti,indexProdotti) in getDocumentiCatalogo"
                                v-bind:key="indexProdotti">

                                <q-item-label header>{{ documento_prodotti.nome }}</q-item-label>

                                <q-item clickable v-ripple
                                    v-for="(documento,indexDocumento) in documento_prodotti.documenti"
                                    v-bind:key="indexDocumento">

                                    <q-item-section avatar>
                                        <q-avatar color="blue-grey" text-color="white" icon="mdi-download" />
                                    </q-item-section>

                                    <q-item-section
                                        @click="ScaricaDocumento(documento.path,documento.id)">
                                        <strong>{{ documento.descrizione }}</strong>
                                    </q-item-section>
                                    <q-item-section side>
                                        <q-item-label caption>{{ trascodificaAzione(documento.azione) }}</q-item-label>
                                    </q-item-section>

                                </q-item>

                            </div>
                        </q-list>
                        <br>
                        Clicca sul nome del documento per scaricarlo in locale
                        <br>
                    </fieldset>
                    <br><br><br><br>
                </div>

                <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
                    v-show="sta_caricando"
                    style="z-index: 1000;">

                    <q-spinner-hourglass
                        size="4em"
                    />

                </div>

                <br><br><br>
            </div>
        </q-page-container>

        <Footer />

    </q-layout> //-->

</template>

<script>
    import commonLib from '@/libs/commonLib';
    import api from '@/libs/api';
    import axios from 'axios';

    export default {
        name: "BoxInformativo",
        data() {
            return {
                id_preventivo: "",
                token: "",
                preventivo: [],
                documenti_catalogo: [],
                documenti_allegati: {},
                sta_caricando: false,
                mio_assicuratore: true,
                dettagli_proposte: true,
                offerta_economica: true,
                documenti_leggere: true,
                documenti_sottoscrivere: true
            }
        },
        methods: {
            trascodificaAzione(azione) {
                switch(azione) {
                    case "COMPILARE_E_FIRMARE":
                        return "COMPILARE E FIRMARE";
                    case "LEGGERE":
                        return "LEGGERE";
                }
            },
            async ScaricaDocumento(url,name) {

                //console.log("name:",name);

                if (url === "Documento non trovato") {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Nessun documento presente nello storage'
                    });
                    return;
                }

                await axios({
                     url:url,
                     method:'GET',
                     responseType: 'blob',
                    headers:{ Authorization:""}
                 })
                 .then((response) => {
                    const url = window.URL
                        .createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target = "_blank";
                    link.setAttribute('download', name+".pdf");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                 });

            },
            trascodificaData(value) {
                if (!commonLib.isEmpty(value)) {
                    var element = value.split(" ")[0];
                    var data_db = element.split("-");

                    return data_db[2]+"/"+data_db[1]+"/"+data_db[0];
                }

                return "";
            },
            getPropostaSelezionata(prodotto) {
                if (!commonLib.isEmpty(prodotto)) {
                    var proposta_selezionata = prodotto.proposte.filter(proposta => {
                        return proposta.is_checked;
                    });

                    if (proposta_selezionata.length !== 0) {
                        return proposta_selezionata[0];
                    }
                }

                return {};
            },
            getPremioProposta(prodotto) {
                if (!commonLib.isEmpty(prodotto)) {
                    var proposta = prodotto.proposte.filter(p => {
                        return p.is_checked;
                    });
                    var premio = //prodotto.consulenza +
                                 //proposta[0].diritti_aby +
                                 proposta[0].premio;

                    return premio.toFixed(2);
                }

                return 0;
            },
            getConsulenzaTotale() {
                var totale = 0;
                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    this.preventivo.json_form.preventivo.prodotti.filter(p => {

                        if (p.is_checked) {
                            var proposte = p.proposte.filter(proposta => {
                                return proposta.is_checked;
                            });
                            totale += parseFloat(proposte[0].diritti_aby) + parseFloat(p.consulenza);
                        }

                        return p.is_checked;
                    });

                    return totale.toFixed(2);
                }

                return 0;
            },
            getNumeroPreventivo() {
                if (!commonLib.isEmpty(this.preventivo.json_form))
                    return this.preventivo.json_form.preventivo.id;

                return "";
            },
            getTafiffaProposta(prodotto) {
                var proposta = this.getPropostaSelezionata(prodotto);
                return proposta.tariffa;
            },
            getPremioTotale() {
                var totale = 0;

                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    this.preventivo.json_form.preventivo.prodotti.filter(p => {

                        if (p.is_checked) {
                            var proposte = p.proposte.filter(proposta => {
                                return proposta.is_checked;
                            });
                            totale += parseFloat(proposte[0].premio) + parseFloat(proposte[0].diritti_aby) + parseFloat(p.consulenza);
                        }

                        return p.is_checked;
                    });

                    return totale.toFixed(2);
                }

                return 0;
            },
            getFrazionamento(prodotto) {
                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    var frazionamento = this.getPropostaSelezionata(prodotto).frazionamento.toUpperCase();
                    if (frazionamento === "") frazionamento = "ANNUALE";

                    return frazionamento;
                }

                return "ANNUALE";
            }
        },
        computed: {
            path_logo() {
                return require("@/images/logo_aby.png");
            },
            isAby() {
                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    return this.preventivo.json_form.intermediario_capo_gruppo.is_abybroker;
                }

                return false;
            },
            getDataScadenzaPreventivo() {
                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    var create_at = new Date();
                    create_at.setDate(create_at.getDate() + 15);
                    var giorno = create_at.getDay()+"";
                    var mese   = (create_at.getMonth() + 1)+"";
                    var anno   = create_at.getFullYear()+"";

                    giorno = giorno.padStart(2,'0');
                    mese = mese.padStart(2,'0');

                    return giorno+'/'+mese+'/'+anno;
                }

                return "";
            },

            getRUIIntermediario() {
                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    return {
                        data_iscrizione: this.preventivo.json_form.persona_operativa.dati_rui.data_iscrizione,
                        numero_rui: this.preventivo.json_form.persona_operativa.dati_rui.numero_rui
                    };
                }

                return {
                    data_iscrizione: "",
                    numero_rui: ""
                };
            },
            getRecapitiIntermediario() {
                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    return {
                        telefono: this.preventivo.json_form.persona_operativa.telefono,
                        email: this.preventivo.json_form.persona_operativa.email,
                        pec: this.preventivo.json_form.persona_operativa.pec
                    };
                }

                return {
                    telefono: "",
                    email: "",
                    pec: ""
                };
            },
            getRagioneSocialeUfficio() {
                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    return this.preventivo.json_form.unita_operativa.nominativo.toUpperCase();
                }

                return "";
            },
            getNominativoCliente() {
                if (!commonLib.isEmpty(this.preventivo)) {
                    return this.preventivo.nominativo.toUpperCase();
                }

                return "";
            },
            getDocumentiCatalogo() {
                //console.log("documenti_catalogo:",this.documenti_catalogo);
                //console.log("documenti_proposta:",this.documenti_allegati);
                return this.documenti_catalogo;
            },
            getDataInserimento() {
                var data = this.preventivo.created_at_onlydate_as_string;
                if (!commonLib.isEmpty(data))
                    return data;

                return "";
            },
            getIntermediario() {
                if (!commonLib.isEmpty(this.preventivo.json_form)) {
                    return this.preventivo.operatore;
                }

                return "";
            },
            getProdottiSelezionati() {
                if (!commonLib.isEmpty(this.preventivo.json_form)) {

                    var prodotti = this.preventivo.json_form.preventivo.prodotti.filter(p => {
                        // Verifica se ci sono proposte selezionate
                        var proposte = p.proposte.filter(proposta => {
                            return proposta.is_checked;
                        });

                        return ((proposte.length === 1) && (p.is_checked));
                    });

                    return prodotti;
                }

                return [];
            }
        },
        components: {
        //    Header: () => import("./Header"),
        //    Footer: () => import("./Footer"),
        },
        async mounted() {
            this.sta_caricando = true;

            // recupera id_preventivo e token da url
            this.id_preventivo = commonLib.getQueryParamFromUrl("id");
            this.token = commonLib.getQueryParamFromUrl("token");

            // Chiede al box-informativo le informazioni della pratica
            var url =  process.env.VUE_APP_API_URL + "box-informativo/preventivo?id="+this.id_preventivo+"&token="+this.token;
            var oJSon = await api.getJSonFromController(url);

            if ((commonLib.isEmpty(oJSon)) || (oJSon.request.status !== 200)) {
                this.$router.push({name: "Unauthorized401"});
                return;
            }

            if (oJSon.data.haveError) {
                this.$router.push({name: "NotFound404"});
                return;
            }

            //console.log(oJSon);
            this.preventivo = oJSon.data.preventivo;
            this.documenti_catalogo = oJSon.data.documenti_catalogo;

            // TODO - Questa istruzione genera un warning di chiave duplicata 0 ma non capisco dove sia
            this.documenti_allegati = oJSon.data.documenti_proposta;

            this.sta_caricando = false;
        }
    }

</script>

<style scoped>

    td.domanda {
        text-align: right;
        font-style: italic;
        font-weight: bold;
    }
    td.risposta {
        border-bottom: 1px SOLID #c0c0c0;
        padding: 6px;
        width: 60%;
        vertical-align: bottom;
    }
    .table {
        width: 100%;
    }
    .table-bordered {
        border-collapse: collapse;
        width: 100%;
    }

    .table-bordered td, .table th {
        border: 1px solid #404040;
        padding: 8px;
    }

    .table-bordered th {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        text-align: left;
        color: #404040;
        background-color: #F0F0F0;
        border: 1px SOLID #404040;

    }
</style>
